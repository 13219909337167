import React, { useEffect, useState } from "react";
import { Container } from "../../helpers/container";
import insta from "./../../assets/images/instagram.png";
import { Button } from "../utilityComponents/button";
import { useWidth } from "../../hooks/useWidth";

export const Instagram = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
          {
instagramPosts(publicationState:PREVIEW,sort: "timestamp"){
  data{
    id
    attributes{
     timestamp
      postUrl
    }
  }
}
}`,
      }),
    })
      .then((res) => res.json())
      .then(
        ({
          data: {
            instagramPosts: { data },
          },
        }) => setPosts(data.reverse())
      );
  }, []);

  const { isDesktop } = useWidth();
  return (
    <Container className="instagram">
      <h1 className="instagram__header">Instagram posts</h1>
      {isDesktop ? (
        <div className="instagram__wrapper">
          <div>
            {posts &&
              posts
                .filter((e, i) => i < 3)
                .map((p: any) => {
                  console.log(p);
                  return (
                    <img
                      className="instagram__img"
                      src={p.attributes.postUrl}
                      alt=""
                    />
                  );
                })}
          </div>
          <div className="instagram__link">
            <img src={insta} />
            <h1>Zobacz więcej na naszym Instagramie</h1>
            <Button
              onClick={() =>
                (window.location =
                  "https://www.instagram.com/koneser_restauracja/")
              }
              label="Odwiedź >"
              type="primary"
              size="small"
            />
          </div>
        </div>
      ) : (
        <>
          {posts && (
            <div
              className="instagram__img instagram__mobile"
              style={{
                backgroundImage: `url(${posts[0]?.attributes?.postUrl})`,
                backgroundSize: "cover",
              }}
            >
              <img src={insta} />
            </div>
          )}
        </>
      )}
    </Container>
  );
};
