import React, { useEffect, useState } from "react";
import bgc from "../../assets/images/signet.png";
import { Button } from "../utilityComponents/button";
import { useWidth } from "../../hooks/useWidth";
import { goToUrl } from "../../helpers/goToUrl";

type MainPageHeaderProps = {
  withButtons?: boolean;
};

export const MainPageHeader = ({ withButtons }: MainPageHeaderProps) => {
  const { isDesktop } = useWidth();

  return (
    <div
      className="mainPageHeaderContainer"
      style={{ backgroundImage: `url(${bgc})` }}
    >
      <h1 className="mainPageHeaderContainer__header fontSegoeBold">
        Przestrzeń Pozytywnych Emocji
      </h1>
      {withButtons ? (
        <div>
          <Button
            style={{ margin: isDesktop ? "2rem" : "1rem 0rem" }}
            label="Zobacz menu"
            type="secondary"
            size="large"
            onClick={() => (window.location = "/menu")}
          />
          <Button
              onClick={() => window.location.href = 'https://koneser.s4honline.pl/'}
            style={{ margin: isDesktop ? "2rem" : "0rem" }}
            label="Zamów online"
            type="primary"
            size="large"
          />
        </div>
      ) : null}
    </div>
  );
};
