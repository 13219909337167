import React, { useEffect } from "react";
import { Layout } from "../components/utilityComponents/layout";
import { MainPageHeader } from "../components/mainPageComponents/mainPageHeader";
import { ImageWithText } from "../components/utilityComponents/imageWithText";
import { News } from "../components/utilityComponents/layoutComponents/news";
import { graphql, StaticQuery } from "gatsby";
import { Container } from "../helpers/container";
import { Instagram } from "../components/mainPageComponents/Instagram";
import { useWidth } from "../hooks/useWidth";
import Helmet from "react-helmet"

export default function IndexPage() {
  const { isDesktop } = useWidth();
  return (
    <StaticQuery
      query={graphql`
        query IndexPage {
          strapiHomepage {
            id
            sectionWithImage {
              title
              isButton
              description
              isLogo
              heroImage {
                url
              }
            }
          }
        }
      `}
      render={({ strapiHomepage }) => {
        return (
          <>
            <Helmet>
                <meta name="facebook-domain-verification" content="mxhgvs6v1e9ocbz5lqk53i4pt54kdu" />
            </Helmet>
            <Layout
              film
              darkBackground
              fullHeightBackground
              headerChildren={<MainPageHeader withButtons />}
              href="#IWT"
            >
              <Instagram />
              <ImageWithText
                style={{
                  lineHeight: "22px",
                }}
                id="IWT"
                {...strapiHomepage.sectionWithImage}
              />
              <News />
            </Layout>
          </>
        );
      }}
    />
  );
}